import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DebounceInput } from 'react-debounce-input'
import Select, { components } from 'react-select'
import { toast } from 'react-toastify'

import { GET, POST, PUT } from 'helpers/api'
import { Html5QrcodeScanner } from "html5-qrcode"
import './style.css'
import { Modal } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { findIdFromArray } from "helpers/helpers"

const qrcodeRegionId = "html5qr-code-full-region";

const Html5QrcodePlugin = (props) => {
	const createConfig = (props) => {
		let config = {
			disableCameraScan: true, // Kamera o‘chirib qo‘yiladi
			supportedScanTypes: [], // Kamera scan turini qo‘shimcha ravishda o‘chiradi
		};
		if (props.fps) {
			config.fps = props.fps;
		}
		if (props.qrbox) {
			config.qrbox = props.qrbox;
		}
		if (props.aspectRatio) {
			config.aspectRatio = props.aspectRatio;
		}
		if (props.disableFlip !== undefined) {
			config.disableFlip = props.disableFlip;
		}
		return config;
	};

	useEffect(() => {
		const config = createConfig(props);
		const verbose = props.verbose === true;
		if (!props.qrCodeSuccessCallback) {
			throw new Error("qrCodeSuccessCallback is required callback.");
		}
		const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
		html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

		return () => {
			html5QrcodeScanner.clear().catch(error => {
				console.error("Failed to clear html5QrcodeScanner.", error);
			});
		};
	}, [props]);

	return <div id={qrcodeRegionId} style={{ width: "100%" }}></div>;
};


function CreateUpdate() {
	const { id } = useParams();

	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxSettings = useSelector(state => state.settings)


	const [posId, setPosId] = useState([])
	const [poses, setPoses] = useState([])
	const [search, setSearch] = useState('')
	const [searchChild, setSearchChild] = useState('')
	const [searchDataChild, setSearchDataChild] = useState([])
	const [data, setData] = useState({ name: "", id: "", token: "", posId: "", domain: "" })
	const [dataChild, setDataChild] = useState([])
	const [UnfindData, setUnfindData] = useState([])
	const [URL, setURL] = useState('')
	const dispatch = useDispatch();

	async function create() {
		var sendData = {
			'products': [],
		}
		sendData.name = data['name']
		sendData.domain = data['domain']
		if(data['id']) sendData['id'] = data['id']
		if(data['token']) sendData.token = data['token']
		sendData.posId = posId

		for (let i = 0; i < dataChild.length; i++) {
			sendData.products.push({
				'id': dataChild[i]['productId']
			})
		}

		console.log(sendData)

		const response = await POST('/services/web/api/dealer', sendData, { loader: true })
		if (response) {
			navigate('/dealer')
		}
		
		
	}

	async function searchProduct(value) {
		setSearchChild(value)
		if (value.length === 0) {
			setSearchDataChild([])
		} else {
			var response = await GET(
				"/services/web/api/product-dealer-helper/",
				{ 'search': value },
				{ loader: true }
			)

			if (response.length > 0) {
				// new product
				var productExistOnResponse = false
				for (let i = 0; i < response.length; i++) {
					if (response[i]['productBarcode'] !== value) {
						productExistOnResponse = true
					}
				}

				if (productExistOnResponse) {
					response.unshift({
						productBarcode: value,
						productDB: false,
						productName: value
					})
				}
				// new product

			}

			var responseCopy = [...response]
			var childDataCopy = [...dataChild]
			for (let i = 0; i < childDataCopy.length; i++) {
				responseCopy = responseCopy.filter(e => e.productBarcode !== childDataCopy[i]['productBarcode'])
			}
			response = [...responseCopy]

			if (response.length === 0) {
				var productExistNoResponse = false
				if (value !== data[0]['productBarcode']) {
					productExistNoResponse = true
				}
				if (productExistNoResponse) {
					for (let i = 0; i < childDataCopy.length; i++) {
						if (childDataCopy[i]['productBarcode'] === value) {
							productExistNoResponse = false
						}
					}
					if (!productExistNoResponse) {
						toast.error(t('ERROR') + ': ' + t('PRODUCT_ADDED'))
					}
				}
				if (productExistNoResponse) {
					response.push({
						productBarcode: value,
						productDB: false,
						productName: value
					})
					addToList(0, response)
					setSearchChild('')
				}
				return
			}

			if (/^\d+$/.test(value) && response.length === 1) {
				setSearchDataChild(response)
				addToList(0, response)
				setSearchChild('')
			} else {
				setSearchDataChild(response)
			}
		}
	}

	

	function addToList(index, arr = []) {
		var searchDataCopy;
		if (arr.length > 0) {
			searchDataCopy = arr
		} else {
			searchDataCopy = [...searchDataChild]
		}

		var dataCopy = [...dataChild]
		dataCopy.push(searchDataCopy[index])

		setDataChild(dataCopy)


		searchDataCopy.splice(index, 1)
		setSearchDataChild(searchDataCopy)

		setSearch('')
	}


	function deleteChild(index) {
		var dataChildCopy = [...dataChild]
		dataChildCopy.splice(index, 1)
		setDataChild(dataChildCopy)
	}

	async function getData() {
		const response = await GET(`/services/web/api/dealer/${id}`, {}, { loader: true })
		if (response) {


			// Update products state (dataChild)
			if (response.products && response.products.length > 0) {
				var mappedProducts = response.products.map(product => ({
					productId: product.id,
					productName: product.name,
					productBarcode: product.barCode,
				}));
				setDataChild(mappedProducts);
			} else {
				console.warn("No products found in the response.");
				setDataChild([]);
			}

			// Update main state (data) in one call
			setData({
				...data, // Spread existing fields
				id: response.id || null,
				domain: response.domain || '',
				name: response.name || '',
				token: response.token || '', // Ensure token is handled if missing
			});

			// Update posId state if needed separately
			setPosId(response.posId || null);
		}
	}

	async function getPoses() {
		const temporaryPoses = await GET('/services/web/api/pos-helper')
		if (temporaryPoses) {
			setPoses(temporaryPoses)
		}

		if (temporaryPoses.length > 0) {
			setPosId(reduxSettings.posId ? reduxSettings.posId : temporaryPoses[0]['id'])
		}
	}

	useEffect(() => {
		getPoses()
		if (id) getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const onNewScanResult = (decodedText) => {
		// handle decoded results here

		setTimeout(() => {
			let textCode = ''
			if (decodedText == 'url') {
				try {
					textCode = JSON.parse(URL.trim()); // JSON formatga o'tkazish
					if (!textCode.name || !textCode.domain || !textCode.bar_code_url) {
						return toast.error(t('ERROR'))
					}
					toast.success(t('SUCCESS'))
				} catch (error) {
					// alert("Xato: Iltimos, to‘g‘ri JSON formatda kiriting.");
					toast.error(t('ERROR'))
					return
				}
			} else {
				try {
					textCode = JSON?.parse(decodedText)

					toast.success(t('SUCCESS'))
				} catch (error) {
					toast.error(t('ERROR'))
					return
				}
			}
			// setQRcode(decodedText);
			if (textCode) {
				getProduct(textCode)
			}
		}, 500)
	}

	const getProduct = async (textCode) => {
		const response = await POST('services/web/api/sd/products', {
			"domain": textCode?.domain,
		}, { loader: true })
		if (response) {
			const UnfindData = response.data.filter(item => item.productId === 0);
			const FindData = response.data.filter(item => item.productId !== 0);
			setDataChild(FindData)
			setUnfindData(UnfindData)
		}



		if (textCode.domain || textCode?.name) {
			setData(prevData => ({
				...prevData,
				domain: textCode?.domain,
				name: textCode?.name
			}));
		}
	}

	useEffect(() => {
		console.log(data);
	}, [data])

	const reduxTransferData = useSelector(state => state.transferData)
	const seasons = [{ id: 0, name: t('EMPTY') }, { id: 1, name: t('SEASONAL') }, { id: 2, name: t('NOT_SEASONAL') }];


	const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange",
		defaultValues: {
			name: '',
			barcode: '',
			uomId: 1,
			categoryId: 2,
			modQuantity: ''
		}
	});
	const { errors } = formState

	const [uoms, setUoms] = useState([]) // for instant create modal
	const [tasnifProductName, setTasnifProductName] = useState('')
	const [modalProductData, setModalProductData] = useState(false)
	const [totalAmount, setTotalAmount] = useState({ totalIncome: 0, totalSale: 0, totalQuantity: 0 })
	const [modalProduct, setModalProduct] = useState(false)
	const [categories, setCategories] = useState([]) // for instant create modal
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [organizations, setOrganizations] = useState([])
	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]
	const [printProducts, setPrintProducts] = useState([])

	async function createProduct(validatedData) {
		if (reduxSettings.ofd) {
			if (validatedData.gtin?.length === 0) {
				return toast.error('ИКПУ ' + t('required'))
			}
			if (validatedData.gtin.split('').every(char => char === validatedData.gtin[0])) {
				return toast.error('ИКПУ ' + t('ERROR'))
			}
		}
		const response = await POST('/services/web/api/product-instant-create', validatedData, { loader: true })
		var product = response.data
		if (response) {
			product.price = ''
			product.wholesalePrice = ''
			product.bankPrice = ''
			product.salePrice = ''
			product.quantity = ''
			product.label = ''
			product.serialNumbers = []
			if (data.defaultVat) {
				product.vat = data.defaultVat
			} else {
				product.vat = 0
			}

			var dataCopy = { ...data }
			var exist = false
			for (let i = 0; i < dataCopy.productList?.length; i++) {
				if (
					product.productId ===
					dataCopy.productList[i]['productId']
				) {
					exist = true
				}
			}

			if (!exist) {
				dataCopy?.productList?.push(product)
				setData(dataCopy)
				let unfindDatacopy = [...UnfindData]
				let DeletedIndex = unfindDatacopy.findIndex((e) => e?.productBarcode === validatedData.barcode)
				unfindDatacopy.splice(DeletedIndex, 1)
				setUnfindData(unfindDatacopy)
				let prd = {
					productId: validatedData.uomId,
					productName: validatedData.name,
					productBarcode: validatedData.barcode,
				}
				let data = [...dataChild]
				data.push(prd)
				setDataChild(data)
				setTimeout(() => {
					const byId = document.getElementById(dataCopy.productList?.length)
					byId?.select()
				}, 200);
			} else {
				toast.error(t('ERROR') + ': ' + t('PRODUCT_IN_LIST'))
			}


			showProductCreateModal(false)
		}
	}

	function showProductDataModal() {
		setModalProductData(true);
		showProductCreateModal(false);
	}

	const [cashbox] = useState({
		cashboxId: 11,
		defaultCurrency: 'So`m',
		hidePriceIn: true,
		loyaltyApi: "",
		posId: 15,
		posName: "idokon test",
		saleMinus: false,
	})

	function countTotalAmount(index, callSetPrice = true, newData) {
		if (index !== undefined && callSetPrice) setPrices(index)

		var dataCopy = { ...data }
		if (newData) {
			dataCopy = newData
		}
		var temporaryTotalQuantity = 0
		var temporaryTotalIncome = 0
		var temporaryTotalSale = 0

		for (let i = 0; i < dataCopy.productList?.length; i++) {
			if (dataCopy.productList[i]['price'] && dataCopy.productList[i]['quantity']) {
				temporaryTotalQuantity += Number(dataCopy.productList[i]['quantity'])
				temporaryTotalIncome += dataCopy.productList[i]['price'] * dataCopy.productList[i]['quantity']
				temporaryTotalSale += dataCopy.productList[i]['salePrice'] * dataCopy.productList[i]['quantity']
			}
		}

		setTotalAmount({ totalIncome: temporaryTotalIncome, totalSale: temporaryTotalSale, totalQuantity: temporaryTotalQuantity })
	}

	function setPrices(index) {
		var dataCopy = { ...data }
		if (dataCopy.productList[index]['price'] !== "") {
			dataCopy.productList[index]['wholesalePrice'] = Number(dataCopy.productList[index]['price']) + Number((dataCopy.productList[index]['price'] * (dataCopy.wholesalePriceMarkup / 100)))
			dataCopy.productList[index]['bankPrice'] = Number(dataCopy.productList[index]['price']) + Number((dataCopy.productList[index]['price'] * (dataCopy.bankPriceMarkup / 100)))
			dataCopy.productList[index]['salePrice'] = Number(dataCopy.productList[index]['price']) + Number((dataCopy.productList[index]['price'] * (dataCopy.salePriceMarkup / 100)))
		} else {
			dataCopy.productList[index]['wholesalePrice'] = ""
			dataCopy.productList[index]['bankPrice'] = ""
			dataCopy.productList[index]['salePrice'] = ""
		}
		setData(dataCopy)
	}

	async function showProductCreateModal(bool) {
		if (!bool) {
			reset()
		}
		if (uoms?.length === 0) {
			const response = await GET('/services/web/api/product-uom-helper')
			setUoms(response)
		}
		if (search?.length > 0) {
			setValue('barcode', search)
			setValue('name', tasnifProductName)
		} else {
			// setValue('barcode', '')
			// setValue('name', '')
		}
		setModalProduct(bool)
	}

	// async function getPoses() {
	//   const temporaryPoses = await GET('/services/web/api/pos-helper')
	//   if (temporaryPoses) {
	//     setPoses(temporaryPoses)
	//   }

	//   const temporaryOrganizations = await GET('/services/web/api/organization-helper')
	//   if (temporaryOrganizations) {
	//     var defaultOrganizations = [{ 'id': -1, 'name': t('ADD_NEW') }]
	//     temporaryOrganizations.forEach(element => {
	//       defaultOrganizations.push(element)
	//     });
	//     setOrganizations(defaultOrganizations)
	//   }
	//   var dataCopy = { ...data }
	//   if (temporaryOrganizations?.length > 0 && temporaryPoses?.length > 0) {
	//     if (
	//       reduxSettings.posId &&
	//       reduxSettings.organizationId &&
	//       findIdFromArray(temporaryPoses, reduxSettings.posId) &&
	//       findIdFromArray(temporaryOrganizations, reduxSettings.organizationId)
	//     ) {
	//       dataCopy.posId = reduxSettings.posId
	//       dataCopy.organizationId = reduxSettings.organizationId
	//       dataCopy.currencyId = findIdFromArray(currencies, reduxSettings.currencyId) ? findIdFromArray(currencies, reduxSettings.currencyId) : 1

	//       dataCopy = setReduxProducts(dataCopy)
	//       setData(dataCopy)
	//       return;
	//     }

	//     dataCopy.posId = temporaryPoses[0]['id']
	//     dataCopy.organizationId = temporaryOrganizations[0]['id']
	//     dataCopy = setReduxProducts(dataCopy)
	//     setData(dataCopy)
	//   } else if (temporaryOrganizations?.length > 0) {
	//     dataCopy.organizationId = temporaryOrganizations[0]['id']
	//     dataCopy = setReduxProducts(dataCopy)
	//     setData(dataCopy)
	//   }
	// }

	function setReduxProducts(data) {
		var dataCopy = { ...data }
		if (reduxTransferData.documentInCreateData?.productList && reduxTransferData.documentInCreateData?.productList?.length) {
			dataCopy = reduxTransferData.documentInCreateData
			dataCopy.productList = reduxTransferData.documentInCreateData.productList
			var arr = []
			for (let i = 0; i < dataCopy.productList?.length; i++) {
				dataCopy.productList[i].price = dataCopy.productList[i].originalPrice
				if (dataCopy.productList[i].checked) {
					arr.push(dataCopy.productList[i])
				}
			}
			setPrintProducts(arr)
			var index = arr?.length - 1
			if (index >= 0) {
				dataCopy.productName = arr[index].name
				dataCopy.barcode = arr[index].barcode
				dataCopy.price = arr[index].price
				dataCopy.salePrice = arr[index].salePrice
				dataCopy.wholesalePrice = arr[index].wholesalePrice
				dataCopy.bankPrice = arr[index].bankPrice
				dataCopy.posName = cashbox.posName
			}
		}
		countTotalAmount(undefined, false, dataCopy)
		return dataCopy
	}

	useEffect(() => {
		getCategories()

		if (reduxSettings.categoryId) {
			setValue('categoryId', reduxSettings.categoryId)
		}
		if (!reduxTransferData?.documentInCreateProduct) {
			dispatch({
				type: 'SET_DOCUMENT_IN_CREATE_PRODUCT', payload: {
					'gtin': false,
					'seasonal': false,
					'modUomId': false,
					'modQuantity': false,
					'complete': false,
				}
			})
		}
	}, [])

	async function getCategories() {
		const response = await GET('/services/web/api/product-category-helper')
		var arr = []
		for (let i = 0; i < response?.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			if (response[i].parentId !== 0) {
				const index = response.findIndex(x => x.id === response[i].parentId)
				response[i].child = true;
				if (response[index].options && response[index].options?.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}
		setCategories(arr)
		// setCategoriesInitial(response)
	}

	function generateRandomBarcode() {
		setValue('barcode', Math.floor(Math.random() * 899999 + 100000))
	}

	async function searchOfdProduct() {
		const response = await GET('/services/web/api/get-ofd-product?barcode=' + getValues('barcode'), {}, { loader: true })
		if (response && response.gtin) {
			setValue('name', response.name)
			setValue('gtin', response.gtin)
		} else {
			return toast.error('ИКПУ ' + t('NOT_FOUND'))
		}
	}

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: '#999',
				fontWeight: 'bold',
				backgroundColor: '#fff',
				fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('DEALERS')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{id ? t('EDIT') : t('CREATE')}</h6>
					</div>
				</div>
			</div>

			<div className="row ">
				<div className="col-md-6  mb-2 " style={{ height: "250px" }} >
					<div className="d-flex justify-content-between bg-white">
						<div className="card p-2">
							<div style={{ width: "230px", height: 'auto' }} >
								<Html5QrcodePlugin
									fps={100}
									qrbox={100}
									disableFlip={false}
									qrCodeSuccessCallback={onNewScanResult}
								/>
							</div>
						</div>
						<div className="card p-2 " style={{ width: "50%", height: 'auto' }}>
							<div className="form-group position-relative mb-0">
								<label>{t('URL')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" onChange={(e) => setURL(e.target.value)} />
							</div>
							<button className="my-2" onClick={(_) => onNewScanResult('url')}>Tekshirish</button>
						</div>
					</div>
					<div className="card ">
						<div className="card-body">
							<div className="form-group">
								<label>{t('POS')}<span className="required-mark">*</span></label>
								<Select
									isDisabled={poses?.length === 1 || id} // Fixed data?.length -> poses?.length
									options={poses}
									value={poses.find(option => option.id === posId)} // Access posId from `data`
									onChange={(option) => setPosId(option.id)} // Ensure proper key is updated
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>

							<div className="form-group position-relative mb-0">
								<label>{t('DEALER')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" value={data.name} onChange={(e) => setData({ ...data, 'name': e.target.value })} />
							</div>
							<br></br>

							<div className="form-group position-relative mb-0">
								<label>{t('DOMAIN')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" value={data.domain} onChange={(e) => setData({ ...data, 'domain': e.target.value })} />
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="card h-100 p-3 vertical-between">
						<div className="h-100">
							<h3 className="mb-2">{t('PRODUCT_SELECTION')}</h3>
							<hr />
							<div className="form-group position-relative mb-0">
								<DebounceInput
									disabled={data?.name?.length === 0}
									value={searchChild}
									onChange={(e) => searchProduct(e.target.value)}
									type="text"
									className="form-control"
									placeholder={t('SEARCH') + '...'}
									debounceTimeout={1000}
								/>

								{searchDataChild?.length > 0 &&
									<i className="uil uil-times search-barcode" onClick={() => { setSearchDataChild([]); setSearchChild('') }}></i>
								}

								{searchDataChild?.length > 0 &&
									<span className="dropdown-search-menu">
										<div className="dropdown-menu-list">
											<span className="dropdown-menu-result">
												<div className="table-responsive p-3">
													<table className="table-striped table">
														<thead>
															<tr>
																<th>{t('NAME_OF_PRODUCT')}</th>
																<th className="text-center">{t('BARCODE')}</th>
																<th className="text-center">{t('ACTION')}</th>
															</tr>
														</thead>
														<tbody>
															{searchDataChild?.map((item, index) => (
																<tr key={index}>
																	<td>{item.productName}</td>
																	<td className="text-center">{item?.productBarcode}</td>
																	<td className="d-flex justify-content-center">
																		<div className="table-action-button table-action-primary-button" onClick={() => addToList(index)}>
																			<i className="uil uil-plus"></i>
																		</div>
																	</td>
																</tr>
															))
															}
														</tbody>
													</table>
												</div>
											</span>
										</div>
									</span>
								}


							</div>

							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th>{t('NAME_OF_PRODUCT')}</th>
											<th className="text-center">{t('BARCODE')}</th>
											<th className="text-center">{t('ACTION')}</th>
										</tr>
									</thead>
									<tbody>
										{dataChild?.map((item, index) => (
											<tr key={index}>
												<td>{item?.productName}</td>
												<td className="text-center">{item?.productBarcode}</td>
												<td>
													<div className="d-flex justify-content-center">
														<div className="table-action-button table-action-danger-button" onClick={() => deleteChild(index)}>
															<i className="uil-times"></i>
														</div>
													</div>
												</td>
											</tr>
										))
										}
									</tbody>
								</table>
								{UnfindData?.length > 0 &&
									<>
										<h3 className="mb-2">{t('Topilmagan mahsulotlar')}</h3>

										<span className="dropdown-search-men">
											<div className="dropdown-menu-list">
												<span className="dropdown-menu-result">
													<div className="table-responsive p-3">
														<table className="table-striped table">
															{/* <thead>
														<tr>
															<th>{t('NAME_OF_PRODUCT')}</th>
															<th className="text-center">{t('BARCODE')}</th>
															<th className="text-center">{t('ACTION')}</th>
														</tr>
													</thead> */}
															<tbody>
																{UnfindData?.map((item, index) => (
																	<tr key={index}>
																		<td>{item.productName}</td>
																		<td className="text-center">{item?.productBarcode}</td>
																		<td className="d-flex justify-content-center">
																			<div className="table-action-button table-action-primary-button" onClick={() => {
																				setValue('name', item?.productName)
																				setValue('barcode', item?.productBarcode)
																				showProductCreateModal(true)
																			}}>
																				<i className="uil uil-plus"></i>
																			</div>
																		</td>
																	</tr>
																))
																}
															</tbody>
														</table>
													</div>
												</span>
											</div>
										</span>
									</>

								}
							</div>
						</div>
						{(posId && data.name && data.domain) &&
							<div className="d-flex flex-wrap items-center justify-content-end">
								<button type="button" className="btn btn-primary btn-rounded btn-wide"
									disabled={data?.length === 0 || dataChild?.length === 0}
									onClick={create}>
									{t("SAVE")}
								</button>
							</div>
						}
					</div>
				</div>
			</div>

			{/* MODAL INSTANT CREATE PRODUCT */}
			<Modal show={modalProduct} animation={false} centered dialogClassName="update-modal-width" backdrop="static"
				onHide={() => showProductCreateModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex justify-content-between align-items-center w-100">
						<Modal.Title>{t('NEW_PRODUCT')}</Modal.Title>
						<i className="uil-setting cursor me-3 fz-20" onClick={() => showProductDataModal()}></i>
					</div>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createProduct)} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME_OF_PRODUCT')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name" {...register('name', { required: true })} autoFocus />
							<span className="text-danger text-sm">{errors.name?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('BARCODE')}<span className="required-mark">*</span></label>
							<div className="position-relative">
								<input type="number" className="form-control" name="barcode" {...register('barcode', { required: true })}
									onKeyUp={(e) => {
										if (e.keyCode === 13) {
											e.preventDefault()
											searchProduct(e.target.value)
										}
										if (e.key === 'ArrowUp') {
											generateRandomBarcode()
										}
									}}
									onChange={(e) => searchProduct(e.target.value)}
								/>
								<span className="text-danger text-sm">{errors.barcode?.type === 'required' && t('required')}</span>
								<i className="uil-sync input-right-icon"
									style={{ right: '30px' }}
									onClick={() => generateRandomBarcode()}></i>
								<i className="uil uil-shield-check input-right-icon" onClick={searchOfdProduct}></i>
							</div>
						</div>
						<div className="form-group">
							<label>{t('CATEGORY')}</label>
							<Controller
								name="categoryId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										components={{ GroupHeading: GroupHead, Option, }}
										classNamePrefix="react-select"
										options={categories}
										value={categoriesInitial.find(option => option.id === getValues('categoryId'))}
										onChange={(e) => setValue('categoryId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.label}
										getOptionValue={(option) => option.id}
									/>}
							/>
							<span className="text-danger text-sm">{errors.categoryId?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('UNIT_OF_MEASUREMENT')}</label>
							<Controller
								name="uomId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={uoms}
										value={uoms.find(option => option.id === getValues('uomId')) || ''}
										onChange={(e) => setValue('uomId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
							<span className="text-danger text-sm">{errors.categoryId?.type === 'required' && t('required')}</span>
						</div>
						{reduxTransferData?.documentInCreateProduct?.complete &&
							<div className="form-group">
								<label>{t("COMPLETED")}%</label>
								<input type="number" className="form-control" name="complete" {...register('complete')} />
							</div>
						}
						{reduxTransferData?.documentInCreateProduct?.gtin &&
							<div className="form-group">
								<label>ИКПУ</label>
								<input type="number" className="form-control" name="gtin" {...register('gtin')} />
								<span className="text-danger text-sm">{errors.gtin?.message}</span>
							</div>
						}
						{reduxTransferData?.documentInCreateProduct?.modQuantity &&
							<div className="form-group">
								<label>{t('ADDITIONAL_QUANTITY')}</label>
								<input type="number" step="0.0001" className="form-control" name="modQuantity" {...register('modQuantity')} />
								<span className="text-danger text-sm">{errors.modQuantity?.message}</span>
							</div>
						}
						{reduxTransferData?.documentInCreateProduct?.modUomId &&
							<div className="form-group">
								<label>{t('ADDITIONAL_UNIT_OF_MEASUREMENT')}</label>
								<Controller
									name="modUomId"
									control={control}
									render={({ field }) =>
										<Select
											//isDisabled={id}
											tabIndex={3}
											{...field}
											options={uoms}
											value={uoms.find(option => option.id === getValues('modUomId'))}
											onChange={(e) => setValue('modUomId', e.id)}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>}
								/>
								<span className="text-danger text-sm">{errors.modUomId?.message}</span>
							</div>
						}
						{reduxTransferData?.documentInCreateProduct?.seasonal &&
							<div className="form-group">
								<label>{t('SEASONALITY')}</label>
								<Controller
									name="seasonal"
									control={control}
									render={({ field }) =>
										<Select
											//isDisabled={id}
											tabIndex={3}
											{...field}
											options={seasons}
											value={seasons.find(option => option.id === getValues('seasonal'))}
											onChange={(e) => setValue('seasonal', e.id)}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>}
								/>
								<span className="text-danger text-sm">{errors.seasonal?.message}</span>
							</div>
						}
						{reduxTransferData?.documentInCreateProduct?.artikul &&
							<div className="form-group">
								<label>{t('ARTIKUL')}</label>
								<input type="text" className="form-control" name="artikul" {...register('artikul')} />
								<span className="text-danger text-sm">{errors.artikul?.message}</span>
							</div>
						}
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => showProductCreateModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL INSTANT CREATE PRODUCT */}
		</>
	)
}

export default CreateUpdate

