const pos = [
	{
		icon: "uil-shop",
		title: 'POS',
		show: false,
		children: [
			{
				icon: "uil-shop",
				title: 'POS',
				show: false,
				children: [
					{
						title: true,
						i18n: "POS"
					},
					{
						url: "/documents-in",
						i18n: "GOOD_RECEPTION",
						icon: "uil-bring-front",
						role: "POS_DOCUMENT_IN",
						youtube_url: 'https://www.youtube.com/watch?v=cC_j7EAKSaQ'
					},
					{
						url: "/sd-purchase",
						i18n: "ORDERS_SD",
						icon: "uil-bring-bottom",
						role: "POS_DOCUMENT_IN"
					},
					{
						url: "/nomenclature",
						i18n: "NOMENCLATURE",
						icon: "uil-list-ul",
						role: "POS_NOMENKLATURA",
						youtube_url: "https://youtu.be/KwXsiqRA6hk?si=X90oQMz1ScM7kIGG"
					},
					{
						url: "/revaluation",
						i18n: "REVALUATION",
						icon: "uil-newspaper",
						role: "POS_REVALUATION",
						youtube_url: "https://youtu.be/tZmYnRbtOAA?si=0gKhvKmjy2W-P1zR"
					},
					{
						url: "/history",
						i18n: "HISTORY",
						icon: "uil-history",
						role: "POS_HISTORY",
						youtube_url: "https://youtu.be/cW-XuzyTmNA?si=eEVHnp-3mppLjXKr"
					},
					{
						url: "/documents-out",
						i18n: "RETURN_TO_SIPPLIER",
						icon: "uil-backspace",
						role: "POS_DOCUMENT_OUT",
						youtube_url: "https://youtu.be/cW-XuzyTmNA?si=zKSSrMloxmGViGt_&t=55"
					},
					{
						url: "/write-off",
						i18n: "WRITE_OFF",
						icon: "uil-trash-alt",
						role: "POS_WRITE_OFF"
					},
					{
						url: "/inventory",
						i18n: "INVENTORY",
						icon: "uil-calculator-alt",
						role: "POS_INVENTORY",
						youtube_url: "https://youtu.be/TpHZiiiIPbU?si=8qDuWbUkX4YYgS1j&t=75"
					},
					{
						url: "/transfer",
						i18n: "DISPLACEMENT",
						icon: "uil-truck",
						role: "POS_TRANSFER",
						youtube_url: 'https://youtu.be/TpHZiiiIPbU?si=Pk7u36yPUl8z2X4o'
					},
					{
						url: "/orders",
						i18n: "ORDERS",
						icon: "uil-import",
						role: "POS_ORDERS"
					},
					{
						url: "/grouping",
						i18n: "GROUPING",
						icon: "uil-object-ungroup",
						role: "POS_PRODUCT_GROUP",
						youtube_url: "https://youtu.be/jsl0j_PN7ck?si=O0PGe1NGUx8GLXEp&t=254"
					},
					{
						url: "/scale",
						i18n: "SCALE",
						icon: "uil-balance-scale",
						role: "POS_SCALE",
						youtube_url: "https://youtu.be/jsl0j_PN7ck?si=dq9ZNv0li9wCF8Jo"
					},
					{
						url: "/package",
						i18n: "PACKAGES",
						icon: "uil-gift",
						role: "POS_PRODUCT_BOX"
					},
					{
						url: "/promotion",
						i18n: "STOCKS",
						icon: "uil-game-structure",
						role: "POS_PROMOTION"
					},
					{
						url: "/cheques",
						i18n: "CHEQUES",
						icon: "uil-invoice",
						role: "POS_CHEQUE",
						youtube_url: 'https://youtu.be/4gmfqCm1viU?si=3gSllUKsIjcJJ4c1'
					},
					{
						url: "/price-tags",
						i18n: "PRICE_TAGS",
						icon: "uil-pricetag-alt",
						role: "POS_PRICE_TAGS",
						youtube_url: 'https://youtu.be/4gmfqCm1viU?si=c8xhiUffQVbYTAxx&t=280'
					},
					{
						url: "/notifications",
						i18n: "NOTIFICATIONS",
						icon: "uil-bell",
						role: "POS_NOTIFICATIONS",
						youtube_url: 'https://youtu.be/4gmfqCm1viU?si=GAefbf_k6-FmixkF&t=137'
					},
					// {
					// 	url: "/reports",
					// 	i18n: "REPORTS",
					// 	icon: "uil-chart-pie-alt",
					// 	role: "POS_REPORTS"
					// },
				]
			},
			{
				icon: "uil-graph-bar",
				title: 'REPORTS',
				show: false,
				children: [
					{
						title: true,
						i18n: "REPORTS"
					},
					{
						url: "/report/balance",
						i18n: "BALANCE_REPORT",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_BALANCE_PRODUCT"
					},
					{
						url: "/report/incomes",
						i18n: "INCOME_REPORT",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_DOCUMENT_IN"
					},
					{
						url: "/report/sales",
						i18n: "SALES_REPORT",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_SALES"
					},
					{
						url: "/report/expenses",
						i18n: "EXPENSE_REPORT",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_EXPENSE"
					},
					{
						url: "/report/expiration-date",
						i18n: "EXPIRATION_DATE_REPORT",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_EXPDATE"
					},
					{
						url: "/report/return-products",
						i18n: "RETURN_OF_GOODS",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_RETURNED_ITEMS"
					},
					{
						url: "/report/gross-income",
						i18n: "GROSS_INCOME",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_GROSS_INCOME"
					},
					{
						url: "/report/category-managment",
						i18n: "CATEGORY_MANAGEMENT",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_MML"
					},
					{
						url: "/report/suppliers",
						i18n: "SUPPLIERS",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_ORGANIZATION"
					},
					{
						url: "/report/clients",
						i18n: "CLIENTS",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_CLIENTS"
					},
					{
						url: "/report/summary-kpi",
						i18n: "SUMMARY_REPORT",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_SUM1"
					},
					{
						url: "/report/summary",
						i18n: "SUMMARY_REPORT_2",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_SUM2"
					},
					// {
					// 	url: "/report/total",
					// 	i18n: "TOTAL_REPORT",
					// 	icon: "uil-chart-pie-alt",
					// 	role: "POS_REPORTS"
					// },
					{
						url: "/report/write-off",
						i18n: "WRITE_OFF_REPORT",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_WRITE_OFF",
						youtube_url: 'https://youtu.be/WYhF8TJVQic?si=NGmNguBphUEv_fd5'
					},
					{
						url: "/report/cashiers",
						i18n: "CASHIERS_REPORT",
						icon: "uil-chart-pie-alt",
						role: "POS_REPORT_CASHIER"
					},
				]
			},
			{
				icon: "uil-chart-line",
				title: 'ANALYTICS',
				show: false,
				role: "SETTINGS",
				children: [
					{
						title: true,
						i18n: "ANALYTICS"
					},
					{
						url: "/report/recommended-orders",
						i18n: "RECOMMENDED_ORDERS",
						icon: "uil-tag-alt",
						role: "POS_REPORT_RECOMMENDED"
					},
					{
						url: "/report/frozen-funds",
						i18n: "FROZEN_FUNDS",
						icon: "uil-chart-down",
						role: "POS_REPORT_FROZEN"
					},
					{
						url: "/report/oos",
						i18n: "OOS",
						icon: "uil-analytics",
						role: "POS_REPORT_OOS"
					},
					{
						url: "/report/abc",
						i18n: "ABC",
						icon: "uil-abacus",
						role: "POS_REPORT_ABC"
					},
					{
						url: "/report/marginality",
						i18n: "MARGINALITY_CATEGORY",
						icon: "uil-percentage",
						role: "POS_REPORT_MARGINALITY"
					},

					{
						url: "/report/product-action",
						i18n: "PRODUCT_ACTION",
						icon: "uil-shopping-cart-alt",
						role: "POS_REPORT_PRODUCT_ACTION"
					},
					{
						url: "/report/product-unsold",
						i18n: "PRODUCT_UNSOLD",
						icon: "uil-shopping-cart-alt",
						role: "POS_REPORT_PRODUCT_ACTION"
					},
				]
			},
			{
				icon: "uil-cell",
				title: 'SETTINGS',
				show: false,
				role: "POS_SETTINGS",
				children: [
					{
						title: true,
						i18n: "SETTINGS"
					},
					{
						url: "/settings",
						i18n: "SETTINGS",
						icon: "uil-setting",
						role: "POS_SETTINGS"
					},
					{
						url: "/pos",
						i18n: "POSES",
						icon: "uil-shop",
						role: "POS_SETTINGS_POS"
					},
					{
						url: "/cashbox",
						i18n: "POSES_CASHIER",
						icon: "uil-money-withdrawal",
						role: "POS_SETTINGS_POS_CASHBOX"
					},
					{
						title: true,
						i18n: "GENERAL"
					},
					{
						url: "/workers",
						i18n: "MY_USERS",
						icon: "uil-user-square",
						role: "MAIN_USERS"
					},
					{
						url: "/products",
						i18n: "PRODUCTS",
						icon: "uil-shopping-cart",
						role: "MAIN_PRODUCTS"
					},
					{
						url: "/dealer",
						i18n: "DEALER",
						icon: "uil-shopping-cart",
						role: "MAIN_PRODUCTS"
					},
					{
						url: "/product-categories",
						i18n: "ADDITIONAL_PRODUCT_CATEGORIES",
						icon: "uil-shopping-cart",
						role: "MAIN_PRODUCTS"
					},
					{
						url: "/suppliers",
						i18n: "SUPPLIERS",
						icon: "uil-truck-loading",
						role: "MAIN_ORGANIZATION"
					},
					{
						url: "/clients",
						i18n: "CLIENTS",
						icon: "uil-users-alt",
						role: "MAIN_CLIENTS"
					},
					{
						url: "/currency",
						i18n: "CURRENCY",
						icon: "uil-money-bill",
						role: "MAIN_CURRENCY"
					},
					{
						url: "/expense",
						i18n: "EXPENSES",
						icon: "uil-money-insert",
						role: "MAIN_EXPENSE"
					},
				]
			},

		]
	}
]

export default pos
