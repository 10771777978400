import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { SET_LOCK_SCREEN } from 'store/settings/actions'
import { useTranslation } from 'react-i18next'
//import io from 'socket.io-client'
import DatePicker from "react-datepicker";

// Reactstrap
import { Modal } from 'react-bootstrap'
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../topbarDropdown/LanguageDropdown";

import logoIcon from "../../assets/images/logo.png";
import clickImage from '../../assets/images/payments/click-dark.png';

import { POST, GET } from 'helpers/api'
import { clearTemporaryStorage, formatMoney } from 'helpers/helpers'

function Header() {
	const { t } = useTranslation()
	const navigate = useNavigate();
	const dispatch = useDispatch()

	//const socketRef = useRef()

	const layoutSelector = useSelector(state => state.Layout)

	const role = localStorage.getItem('role')

	const [modalLogout, setModalLogout] = useState(false)
	const [balance, setBalance] = useState(0)
	const [notifications, setNotifications] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const [selectedNotification, setSelectedNotification] = useState(null);

	const [billingMenu, setBillingMenu] = useState(false)
	const [posData, setPosData] = useState([]);
	const [showModalClick, setShowModalClick] = useState(false);
	const [selectedPosId, setSelectedPosId] = useState(null);
	const [paidFromDateClick, setPaidFromDateClick] = useState(null);
	const [amountIn, setAmountIn] = useState("");

	const [rightDropdownOpen, setRightDropdownOpen] = useState(false);

	const toggleRightDropdown = () => {
		setRightDropdownOpen(!rightDropdownOpen);
	};

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPosData(response)
		}
	}

	// Handle number input change
	const handleNumberChange = (event) => {
		const value = event.target.value;
		if (/^\d*$/.test(value)) { // Ensure input is an integer
			setAmountIn(value);
		}
	};

	useEffect(() => {
		getPoses()
	}, [])


	const toggleBilling = () => {
		setBillingMenu(!billingMenu)
	}

	/* CLICK */
	// Handle select change
	const handleSelectChangeClick = (event) => setSelectedPosId(event.target.value);

	const handleDateChangeClick = (event) => setPaidFromDateClick(event);

	// Open modal on button click
	const handleOpenModalClick = () => setShowModalClick(true);

	// Close modal
	const handleCloseModalClick = () => {
		setShowModalClick(false);
		setSelectedPosId(null);
		setAmountIn("");
	};


	const redirectToClick = async () => {
		try {
			const sendData = {
				id: selectedPosId,
				amountIn: amountIn,
				paidFrom: paidFromDateClick
			};
			// Replace with your actual API endpoint
			const response = await POST('/services/web/api/get-click-url', sendData, { loader: true })

			if (response && response.data && response.data.url) {
				const redirectUrl = response.data.url;

				// Redirect to the URL received from the API
				window.location.href = redirectUrl;
			} else {
				console.error('Failed to fetch URL');
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const isButtonDisabledClick = amountIn < 1000 || !selectedPosId || !paidFromDateClick;
	/* END CLICK */



	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	function lockScreen() {
		dispatch(SET_LOCK_SCREEN())
	}

	function tToggle() {
		var body = document.body;
		let btn = document.querySelector('.navbar-header')
		btn.classList.toggle('left-margin')
		body.classList.toggle("vertical-collpsed");
		body.classList.toggle("sidebar-enable");
	}


	async function logout() {
		if (process.env.NODE_ENV === 'development') {
			dispatch({ type: 'USER_LOGGED_OUT', payload: null })
			clearTemporaryStorage()
			navigate('/auth/login')
			window.location.reload()
			return
		}
		const response = await POST('/auth/logout')
		if (response) {
			clearTemporaryStorage()
			navigate('/auth/login')
			window.location.reload()
		}
	}

	async function getBalance() {
		const response = await GET('/services/web/api/pos-balance')
		setBalance(response)
	}

	async function getNotifications() {
		const response = await GET('/services/web/api/unread-notifications')
		setNotifications(response)
	}

	const handleDropdownToggle = () => {
		setShowDropdown(!showDropdown);
	};

	const handleNotificationClick = (notification) => {
		setSelectedNotification(notification);
	};

	const handleCloseModal = () => {
		setSelectedNotification(null);
	};



	let locale = "ru"
	if (localStorage.getItem("I18N_LANGUAGE") === 'ru') locale = "ru"
	else if (localStorage.getItem("I18N_LANGUAGE") === 'uz-Latn-UZ') { locale = "lat" }
	else if (localStorage.getItem("I18N_LANGUAGE") === 'uz-Cyrl-UZ') { locale = "cyr" }


	useEffect(() => {
		//socketConnection()
		if (role !== 'ROLE_ORGANIZATION') {
			getBalance()
			getNotifications()
		}

		// return () => {
		// 	socketRef?.current?.disconnect()
		// }
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const [shortTexts, setShortTexts] = useState([]);

	useEffect(() => {
		// Function to convert HTML to plain text and extract the first 20 characters
		const processShortHtmlContent = (htmlContent) => {
			// Create a temporary div element to convert HTML to plain text
			const tempDiv = document.createElement('div');
			tempDiv.innerHTML = JSON.parse(htmlContent)[locale];

			// Get the plain text from the HTML
			const plainText = tempDiv.textContent || tempDiv.innerText;

			// Extract the first 20 characters from the plain text
			return plainText.substring(0, 20) + '...';
		};

		// Function to convert HTML to plain text and extract the first 20 characters
		const processHtmlContent = (htmlContent) => {
			// Create a temporary div element to convert HTML to plain text
			const tempDiv = document.createElement('div');
			tempDiv.innerHTML = JSON.parse(htmlContent)[locale];

			// Get the plain text from the HTML
			const plainText = JSON.parse(htmlContent)[locale];

			// console.log(plainText)

			// Extract the first 20 characters from the plain text
			return plainText;
		};

		// Process each JSON object and extract the short text
		const processedTexts = notifications.map(item => ({
			id: item.id,
			shortText: processShortHtmlContent(item.message),
			longText: processHtmlContent(item.message)
		}));

		// Update the state with the array of short texts
		setShortTexts(processedTexts);
	}, [notifications]);

	return (
		<>
			<header id="page-topbar" className={`d-flex justify-content-between align-items-center ${layoutSelector.showSidebar ? 'sidebar-left' : 'd-none sidebar-active-left'} flex-wrap`}>
				<div className="navbar-header  p-0">
					<div className="d-flex align-items-center">
						<button
							type="button"
							onClick={() => tToggle()}
							className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn me-2"
							id="vertical-menu-btn"
						>
							<i className="uil-bars fz-20" />
						</button>
					</div>
				</div>
				<div className="d-flex">
					<div className="d-flex align-items-center flex-wrap ">
						<LanguageDropdown />

						<button
							type="button"
							onClick={() => handleDropdownToggle()}
							className="btn header-item noti-icon waves-effect me-2"
						>
							<i className="uil-bell position-relative" style={{ fontSize: '24px' }}>
								{notifications.length > 0 && (
									<span
										className="position-absolute bg-warning text-white rounded-circle"
										style={{
											top: '-5px',
											right: '-5px',
											padding: '2px 6px',
											fontSize: '12px',
										}}
									>
										{notifications.length}
									</span>
								)}
							</i>
						</button>

						{showDropdown && (
							<div className="position-absolute bg-light border" style={{ top: '50px', zIndex: 1050 }}>
								{shortTexts.length > 0 ? (
									shortTexts.map((notification) => (
										<div
											key={notification.id}
											onClick={() => handleNotificationClick(notification)}
											className="px-3 py-2 cursor-pointer border-bottom"
										>
											{notification.shortText}
										</div>
									))
								) : (
									<div className="px-3 py-2 text-muted">No notifications</div>
								)}
							</div>
						)}

						<button
							type="button "
							onClick={() => toggleFullscreen()}
							className="btn header-item noti-icon waves-effect me-2 d-none d-md-flex"
						>
							<i className="uil-minus-path"></i>
						</button>

						<button
							type="button"
							onClick={() => lockScreen()}
							className="btn header-item noti-icon waves-effect me-2 "
						>
							<i className="uil-lock"></i>
						</button>

						<div className="d-none d-md-flex">
							<div className=" align-items-center me-2">
								<button type="button" className="btn header-item  noti-icon waves-effect" onClick={() => setModalLogout(true)}>
									<div className="d-flex justify-content-between align-items-center">
										<div className="d-block text-start">
											<div>{t('LOGIN')}: {JSON.parse(localStorage.getItem('authUser'))?.username?.replaceAll('!2929s', '')}</div>
											<div>{t('BALANCE')}: {formatMoney(balance, 0)}</div>
										</div>
										<i className="uil-signin"></i>
									</div>
								</button>
							</div>

							<Dropdown isOpen={billingMenu} toggle={toggleBilling} className="d-inline-block me-2">
								<DropdownToggle className="btn btn-success text-white header-item waves-effect d-inline-flex align-items-center gap-2">
									<i className="uil-dollar-sign" style={{ fontSize: '24px' }}></i>
									<p className="mb-0 ">{t('MAKE_PAYMENT_FOR_BILLLING')}</p>
								</DropdownToggle>
								<DropdownMenu className="dropdown-menu-end">
									<DropdownItem onClick={handleOpenModalClick} className="notify-item">
										<img src={clickImage} alt="react" className="me-1" height="20" />
										<span className="align-middle">Click</span>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</div>
					</div>

					{/* Mobile View */}
					<div style={{backgroundColor: "white"}} className="d-flex align-items-center me-2 d-flex d-md-none">
						<div
							onClick={toggleRightDropdown}
							className="fs-4"
						>
							<i className="uil-ellipsis-v"></i>
						</div>

						{rightDropdownOpen && (
							<div className="position-absolute bg-white border" style={{ top: '50px', right: '10px', zIndex: 1050 }}>
								<div className="d-flex align-items-center justify-content-between w-100 me-2">
									<div type="button" className="btn header-item  noti-icon waves-effect" onClick={() => setModalLogout(true)}>
										<div className="d-flex w-100 justify-content-between align-items-center">
											<div className="d-block text-start">
												<div>{t('LOGIN')}: {JSON.parse(localStorage.getItem('authUser'))?.username?.replaceAll('!2929s', '')}</div>
												<div>{t('BALANCE')}: {formatMoney(balance, 0)}</div>
											</div>
											<i className="uil-signin"></i>
										</div>
									</div>
								</div>

								<Dropdown isOpen={billingMenu} toggle={toggleBilling} className="d-inline-block me-2">
									<DropdownToggle className="btn btn-success text-white header-item waves-effect d-inline-flex align-items-center gap-2">
										<i className="uil-dollar-sign" style={{ fontSize: '24px' }}></i>
										<p className="mb-0 ">{t('MAKE_PAYMENT_FOR_BILLLING')}</p>
									</DropdownToggle>
									<DropdownMenu className="dropdown-menu-end">
										<DropdownItem onClick={handleOpenModalClick} className="notify-item">
											<img src={clickImage} alt="react" className="me-1" height="20" />
											<span className="align-middle">Click</span>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>

							</div>
						)}
					</div>
				</div>
			</header>


			{/* MODAL Click.uz */}
			<Modal show={showModalClick} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={handleCloseModalClick}>
				<Modal.Header closeButton>
					<Modal.Title>
						Click
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form autoComplete="off">
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="text" value={amountIn} className="form-control" onChange={handleNumberChange} />
						</div>
						<div className="form-group">
							<label>{t('POS')}<span className="required-mark">*</span></label>
							<select value={selectedPosId || ""} onChange={handleSelectChangeClick}>
								<option value="" disabled>{t('CHOOSE_POS')}</option>
								{posData.map((item) => (
									<option key={item.id} value={item.id}>
										{item.name}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<label>{t('PAID_FROM')}<span className="required-mark">*</span></label>
							<DatePicker className="form-control"
								dateFormat="dd.MM.yyyy"
								required="required"
								selected={paidFromDateClick}
								onChange={(date) => {
									setPaidFromDateClick(date)
								}} />
						</div>
						<div className="alert alert-warning mt-2">
							{t('BILLING_NOTIFICATION')}
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={handleCloseModalClick}>{t('CANCEL')}</button>
							<button type="button" className="btn btn-primary w-100" onClick={redirectToClick} disabled={isButtonDisabledClick}>{t('GO_TO_PAYMENT')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* end MODAL click.uz */}

			<Modal show={modalLogout} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalLogout(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('LOGOUT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT_LOGOUT')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setModalLogout(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => logout()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Header
