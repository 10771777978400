import React, { Fragment, useEffect, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'

import { showSidebar } from 'store/layout/actions'
//Import Scrollbar
import { Nav } from 'react-bootstrap'
import { exportMenu } from './menues/owner/menu'
import { exportMenuOrganization } from './menues/organization/menu'

import logo from "assets/images/logo.svg";

function SidebarContent() {
	const role = localStorage.getItem('role');

	const { t } = useTranslation()
	const location = useLocation()
	// const navigate = useNavigate()
	const dispatch = useDispatch()

	const reduxAccount = useSelector(state => state.account)

	const [menu, setMenu] = useState([])
	const [activeItemParent, setActiveItemParent] = useState(exportMenu[0].children[0])
	const [activeItem, setActiveItem] = useState(exportMenu[0])

	function setActiveMenu(copyMenu) {
		var menuCopy = [...menu]
		if (copyMenu?.length > 0) {
			menuCopy = copyMenu
		}
		for (let k = 0; k < menuCopy.length; k++) {
			var grandParent = menuCopy[k]
			for (let i = 0; i < grandParent?.children?.length; i++) {
				var parent = grandParent?.children[i];
				for (let j = 0; j < parent?.children?.length; j++) {
					var child = parent?.children[j]
					if (location.pathname.includes(child?.url)) {
						child['active'] = true;
						setActiveItem(grandParent)
						setActiveItemParent(parent)
					} else {
						child['active'] = false;
					}
				}
			}
		}
		setMenu(menuCopy);
	}

	function setActiveGrandParent(item) {
		dispatch(showSidebar())
		var copy = { ...item }
		setActiveItem(copy)
		setActiveItemParent(copy?.children[0])
	}

	function setActiveParent(item) {
		var copy = { ...item }
		setActiveItemParent(copy)
	}

	function setActiveChild(item) {
		var copy = { ...activeItemParent }
		for (let i = 0; i < copy?.children.length; i++) {
			copy.children[i].active = false
		}
		const index = copy?.children?.findIndex(x => x.url === item.url);
		if (index !== -1 && copy?.children[index]) {
			copy.children[index].active = true
			setActiveItemParent(copy)
		}
	}

	function tToggle() {
		var body = document.body;
		body.classList.toggle("vertical-collpsed");
		body.classList.toggle("sidebar-enable");
	}

	function getMenu() {
		if (reduxAccount?.authorities) {
			var authorities = JSON.parse(JSON.stringify(reduxAccount?.authorities))
			var menuCopy = [...exportMenu]
			if (role === 'ROLE_ORGANIZATION') {
				menuCopy = exportMenuOrganization;
			}
			for (let k = 0; k < menuCopy.length; k++) {
				var grandParent = menuCopy[k]
				for (let i = 0; i < grandParent?.children?.length; i++) {
					var parent = grandParent?.children[i];
					for (let j = 0; j < parent?.children?.length; j++) {
						var child = parent?.children[j]
						for (let k = 0; k < authorities?.length; k++) {
							if (child['role'] === authorities[k] || child['title']) {
								child['show'] = true
								parent['show'] = true
							}
						}
					}

					if (parent['show']) {
						grandParent['show'] = true
					}
				}
			}
			var activeMenu = { children: [] };
			var activeMenuParent = { children: [] };
			for (let i = 0; i < menuCopy.reverse().length; i++) {
				if (menuCopy[i].show) {
					activeMenu = menuCopy[i]
					activeMenuParent = menuCopy[i].children[0]
				}
			}
			setActiveItem(activeMenu)
			setActiveItemParent(activeMenuParent)

			setMenu([...menuCopy])

			setActiveMenu(menuCopy)
		}

	}

	useEffect(() => {
		getMenu();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// useEffect(() => {
	// 	setActiveMenu()
	// }, [location]) // eslint-disable-line react-hooks/exhaustive-deps
	const [show, setShow] = useState(false);
	return (
		<>

			<div className="second-sidebar">
				<div className="second-sidebar-logo">
					<Link to="/welcome">
						<img src={logo} alt="" width={120} />
					</Link>
				</div>

				{/* <button style={{ position: 'absolute', right: '0', top: '0' }}
					tabIndex="-1"
					onClick={() => {
						tToggle();
					}}
					type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn">
					<i className="uil-bars fz-20" />
				</button> */}

				<div id="sidebar-menu">
					<Nav className="nav-child-mini" variant="tabs" activeKey={activeItemParent.icon}>
						{activeItem?.children?.map((item, index) => (
							item?.show &&
							<Nav.Item onClick={() => setActiveParent(item)} key={index}>
								<Nav.Link eventKey={item.icon} tabIndex="-1">
									<span className="fw-bold"><i className={item.icon}></i></span>
								</Nav.Link>
							</Nav.Item>
						))}
					</Nav>

					{/* <div className="sidebar-title">
						{t(activeItemParent?.title)}
					</div> */}

					<ul className="metismenu list-unstyled" id="side-menu">
						{activeItemParent?.children?.map((item, index) => {

							return (
								<Fragment key={index}>
									{
										(item.show) &&
										(
											item.title ?
												<div className="sidebar-title">
													{t(item.i18n)}
												</div>
												:
												<li className={item.active ? "mm-active" : "d-flex"} >
													<NavLink to={item.url}
														onClick={() => setActiveChild(item)} className={"waves-effect d-flex justify-content-between w-100" + (item.active ? "active" : "")}>
														<div className="d-flex">
															<i className={item.icon}></i>
															<div className="vertical-menu-div">{t(item.i18n)}</div>
														</div>
														{item.youtube_url &&
															<YoutubePopover youtubeUrl={item.youtube_url} />
														}

													</NavLink>
												</li>
										)
									}
								</Fragment>
							)
						}
						)}
					</ul>
				</div>
			</div>
		</>
	)
}

export default SidebarContent

function YoutubePopover({ youtubeUrl }) {

	return (
		<NavLink onClick={(e) => e.stopPropagation()} target="_blank" to={youtubeUrl} className="flex">
			<svg color="gray" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
				<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
			</svg>
		</NavLink>
	);
}
