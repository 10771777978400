import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DebounceInput } from 'react-debounce-input'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { GET, POST, PUT } from 'helpers/api'

function ViewPurchase() {
	const { id } = useParams();
	const location = useLocation();

	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxSettings = useSelector(state => state.settings)

	const [posId, setPosId] = useState([])
	const [poses, setPoses] = useState([])
	const [dealerId, setDealerId] = useState(null)
	const [dealers, setDealers] = useState([])
	const [search, setSearch] = useState('')
	const [note, setNote] = useState('')
	const [searchDataProduct, setSearchDataProduct] = useState([])
	const [data, setData] = useState({ id: 0 })
	const [dataProduct, setDataProduct] = useState([])

	async function create() {
		var sendData = {
			products: [],
			posId: posId,
			dealerId: dealerId,
			note: note,
			totalAmount: 0, // Initialize as 0
			totalQuantity: 0, // Initialize as 0
		};

		if (data['id']) sendData['id'] = data['id'];

		// Get form data
		const formData = getFormData();

		// Calculate totals
		const filteredProducts = formData.filter(product => product.quantity && parseFloat(product.quantity) > 0);
		const totals = filteredProducts.reduce(
			(totals, product) => {
				const quantity = parseFloat(product.quantity) || 0; // Convert quantity to number or default to 0
				const price = parseFloat(product.price) || 0;       // Convert price to number or default to 0
				totals.totalQuantity += quantity;
				totals.totalAmount += quantity * price;
				return totals;
			},
			{ totalQuantity: 0, totalAmount: 0 } // Initial totals
		);

		// Assign filtered products and totals to sendData
		sendData.products = filteredProducts;
		sendData.totalQuantity = totals.totalQuantity;
		sendData.totalAmount = totals.totalAmount;

		console.log("sendData:", sendData);

		if (totals.totalAmount > 0) {
			const response = await POST('/services/web/api/sd-purchase', sendData, { loader: true })
			if (response) {
				navigate('/sd-purchase')
			}
		}


	}

	const getFormData = () => {
		return dataProduct?.map((item, index) => {
			const quantityInput = document.querySelector(`input[name="quantity"][productQuantity="${item.id}"]`);
			const priceInput = document.querySelector(`input[name="price"][productPrice="${item.id}"]`);

			const quantity = quantityInput ? parseFloat(quantityInput.value) || 0 : 0; // Convert to number or default to 0
			const price = priceInput ? parseFloat(priceInput.value) || 0 : 0;         // Convert to number or default to 0

			return {
				name: item.name,
				id: item.id,
				barcode: item.barcode,
				quantity: quantity.toString(), // Convert back to string if needed
				price: price.toString(),       // Convert back to string if needed
				totalAmount: quantity * price, // Calculate totalAmount
			};
		});
	};

	async function searchProduct(value) {
		setSearch(value)
		if (value?.length === 0) {
			setSearchDataProduct([])
		} else {
			var responseAll = await GET(
				"/services/web/api/dealer-products/" + dealerId,
				{ 'search': value },
				{ loader: true }
			)
			var response = responseAll ? responseAll.products : []

			var responseCopy = [...response]
			console.log("response: " + responseAll)
			var productDataCopy = [...dataProduct]
			for (let i = 0; i < productDataCopy.length; i++) {
				responseCopy = responseCopy.filter(e => e.barCode !== productDataCopy[i]['barCode'])
			}
			response = [...responseCopy]


			if (/^\d+$/.test(value) && response?.length === 1) {
				setSearchDataProduct(response)
				addToList(0, response)
				setSearch('')
			} else {
				setSearchDataProduct(response)
			}
		}
	}

	useEffect(() => {
		console.log(data);

	}, [data])


	function addToList(index, arr = []) {
		var searchDataCopy;
		if (arr.length > 0) {
			searchDataCopy = arr
		} else {
			searchDataCopy = [...searchDataProduct]
		}

		var dataCopy = [...dataProduct]
		dataCopy.push(searchDataCopy[index])

		setDataProduct(dataCopy)


		searchDataCopy.splice(index, 1)
		setSearchDataProduct(searchDataCopy)

		setSearch('')
	}


	function deleteProduct(index) {
		var dataProductCopy = [...dataProduct]
		dataProductCopy.splice(index, 1)
		setDataProduct(dataProductCopy)
	}

	async function getData() {
		const response = await GET(`/services/web/api/sd-purchase/${id}`, {}, { loader: true })
		if (response) {


			// Update products state (dataProduct)
			if (response.products && response.products.length > 0) {
				var mappedProducts = response.products.map(product => ({
					name: product.name,
					barCode: product.barCode,
					quantity: product.quantity,
					price: product.price,
					uomName: product.uomName,
				}));
				setDataProduct(mappedProducts);
			} else {
				console.warn("No products found in the response.");
				setDataProduct([]);
			}

			// Update main state (data) in one call
			setData({
				...data, // Spread existing fields
				id: response.id || null,
				note: response.note
			});

			// Update posId state if needed separately
			setPosId(response.posId || null);

			setDealerId(response.dealerId || null);
		}
	}



	async function getPoses() {
		try {
			const temporaryPoses = await GET('/services/web/api/pos-helper');
			if (temporaryPoses) {
				setPoses(temporaryPoses);
				if (temporaryPoses.length > 0) {
					setPosId(reduxSettings.posId ? reduxSettings.posId : temporaryPoses[0]['id']);
				}
			}
		} catch (error) {
			console.error('Error fetching poses:', error);
		}
	}

	async function getDealers() {
		try {
			const temporaryDealers = await GET('/services/web/api/dealer-helper');
			if (temporaryDealers) {
				setDealers(temporaryDealers);
				// changeDealerId(temporaryDealers, temporaryDealers[0]['id']); 
			}
		} catch (error) {
			console.error('Error fetching dealers:', error);
		}
	}



	function getProductsForDealer(dealers, dealerId) {
		// Ensure `dealers` is an array
		if (!Array.isArray(dealers)) {
			console.error("Dealers is not an array:", dealers);
			return [];
		}

		const dealer = dealers.find((dealer) => dealer.id === dealerId);
		return dealer ? dealer.products : [];
	}

	async function changeDealerId(dealers, id) {
		setDealerId(id)
		setSearch('')
		setDataProduct([]);

	}

	useEffect(() => {
		getPoses();
		getDealers();
		console.log(location.state?.details);

		var accept_product = location.state?.details
		let Products = {

			name: accept_product.product_name,
			barCode: accept_product.bar_code,
			quantity: accept_product.quantity,
			price: accept_product.price,
			uomName: accept_product.uomName,
		}
		setPosId(accept_product.posId)
		setDealerId(accept_product.dealerId)
		setDataProduct([Products]);
		// if (id) getData();
	}, [id]); // Re-run effect if `id` changes

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('ORDERS_SD')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{id ? t('EDIT') : t('CREATE')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body position-relative">
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label>{t('POS')}<span className="required-mark">*</span></label>
								<Select
									options={poses}
									menuIsOpen={false}
									value={poses.find(option => option.id === posId)} // Access posId from `data`
									onChange={(option) => setPosId(option.id)} // Ensure proper key is updated
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label>{t('DEALER')}<span className="required-mark">*</span></label>
								<Select
									options={dealers}
									menuIsOpen={false}
									value={dealers.find(option => option.id === dealerId)} // Access posId from `data`
									onChange={(option) => changeDealerId(dealers, option.id)} // Ensure proper key is updated
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						</div>

						<div className="form-group position-relative mb-0">
							<label>{t('COMMENT')}</label>
							<input type="text" className="form-control" value={data.note} onChange={(e) => setNote(e.target.value)} disabled={true} />
						</div>

					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body position-relative">
					<div className="row">
						<div className="form-group position-relative mb-0">
							{(data.id == null && dealerId) &&
								<DebounceInput
									value={search}
									onChange={(e) => searchProduct(e.target.value)}
									type="text"
									className="form-control"
									placeholder={t('SEARCH') + '...'}
									debounceTimeout={1000}
								/>
							}
							{searchDataProduct?.length > 0 &&
								<i className="uil uil-times search-barcode" style={{ top: '0', transform: 'unset', right: '15px' }}
									onClick={() => { setSearchDataProduct([]); setSearch('') }}></i>
							}

							{searchDataProduct?.length > 0 &&
								<span className="dropdown-search-menu" style={{ paddingRight: '1rem' }}>
									<div className="dropdown-menu-list">
										<span className="dropdown-menu-result">
											<div className="table-responsive p-3">
												<table className="table-striped table">
													<thead>
														<tr>
															<th className="col-md-4">{t('NAME_OF_PRODUCT')}</th>
															<th className="text-center">{t('BARCODE')}</th>
															<th className="text-center">{t('UNIT')}</th>
															<th className="text-center">{t('PRICE')}</th>
															<th className="text-center">{t('ACTION')}</th>
														</tr>
													</thead>
													<tbody>
														{searchDataProduct?.map((item, index) => (
															<tr key={index}>
																<td>{item.name}</td>
																<td className="text-center">{item.barCode}</td>
																<td className="text-center">{item.uomName}</td>
																<td className="text-center">{item.price}</td>
																<td className="d-flex justify-content-center">
																	<div className="table-action-button table-action-primary-button" onClick={() => addToList(index)}>
																		<i className="uil uil-plus"></i>
																	</div>
																</td>
															</tr>
														))
														}
													</tbody>
												</table>
											</div>
										</span>
									</div>
								</span>
							}

							<br />
						</div>
						<div className="table-responsive mb-3">
							<table className="table table-striped mb-0">
								<thead>
									<tr>
										<th className="col-md-4">{t('NAME_OF_PRODUCT')}</th>
										<th className="text-center">{t('BARCODE')}</th>
										<th className="text-center">{t('QUANTITY')}</th>
										<th className="text-center">{t('UNIT')}</th>
										<th className="text-center">{t('PRICE')}</th>
										{data.id == null &&
											<th className="text-center table-control">
												{t('ACTION')}
											</th>
										}
									</tr>
								</thead>
								<tbody>
									{dataProduct?.map((item, index) => (
										<tr key={index} className="vertical-middle">
											<td>{item.name}</td>
											<td className="text-center">{item.barCode}</td>
											<td className="text-center">
												<input type="text" className={"auto-width-input"}
													name="quantity" value={item.quantity}
													id={index + 1}
													productQuantity={item.id}
													disabled
												/>
											</td>
											<td className="text-center">{item.uomName}</td>
											<td className="text-center">
												<input type="text" className={"auto-width-input " + (item.price?.length > 9 && "required-border second")}
													name="price" value={item.price}
													productPrice={item.id}
													disabled
												/>
											</td>
											{data.id == null &&
												<td>
													<div className="d-flex justify-content-center">
														<div className="table-action-button table-action-danger-button" onClick={() => deleteProduct(index)}>
															<i className="uil-times"></i>
														</div>
													</div>
												</td>
											}
										</tr>
									))}
								</tbody>
							</table>
						</div>

						{data.id == null &&
							<div className="d-flex flex-wrap items-center justify-content-end">
								<button type="button" className="btn btn-primary btn-rounded btn-wide"
									disabled={data?.length === 0 || dataProduct?.length === 0}
									onClick={create}>
									{t("SAVE")}
								</button>
							</div>
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default ViewPurchase
