import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import DeleteModal from 'pages/parts/DeleteModal'
import { GET, DELETE, POST } from 'helpers/api'
import { Html5QrcodeScanner } from "html5-qrcode"
import axios from "axios"
import { toast } from "react-toastify"


function Index() {
	const URL = "/services/web/api/dealer-pageList"
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)

	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })
	const [data, setData] = useState([])

	async function getData() {
		const response = await GET(URL)
		if (response) {
			setData(response)
			console.log(data)
		}
	}

	async function generateConnection(dilerId, posId, posName, domain) {
		const response = await POST('/services/web/api/connection', 
			{
				post_id: `i-${posId}-${dilerId}`,
				name: posName,
				phone: "+998(90) 240-79-40",
				domain: domain
			},
			{ loader: true })
		if (response) {
			toast.success(t('SENDED'))
			// getData()
		}

	}

	async function deleteItem() {
		const response = await DELETE("/services/web/api/dealer/"
			+ deleteModalParams.id, {}, { loader: true })
		if (response) {
			getData()
			setModalDelete(false)
		}
	}

	async function search(data, searchValue) {
		setData(data);
	}

	useEffect(() => {
		getData()
	}, [])

	let statuses = [{
		name: "Yangi",
		value: "Новый"
	},{
		name: "Jo‘natilgan",
		value: "Отгружен"
	},{
		name: "Yetkazilgan",
		value: "Доставлен"
	},{
		name: "Qaytarilgan",
		value: "Возврат"
	},{
		name: "Bekor qilingan",
		value: "Отменен"
	}]



	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('DEALERS')}
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/dealer/create">
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('DEALER')}</th>
									<th>{t('DOMAIN')}</th>
									<th>{t('POS')}</th>
									<th className="text-center">{t('Ulanish uchun so\'rov')}</th>
									<th className="text-center table-control">
										{t('ACTION')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{item.name}</td>
										<td>{item.domain}</td>
										<td>{item.posName}</td>
										<td className="text-center">
											<button
												type="button"
												className="btn btn-primary btn-rounded btn-wide"
												onClick={() => generateConnection(item.id, item.posId, item.posName, item.domain)}
											>
												{t('SEND')}
											</button>
										</td>
										<td className="d-flex justify-content-center">
											<Link to={`/dealer/update/${item.id}`} className="me-2">
												<div className="table-action-button table-action-primary-button">
													<i className="uil-edit-alt"></i>
												</div>
											</Link>
											<div className="table-action-button table-action-danger-button"
												onClick={() => {
													setDeleteModalParams({ id: item.id, index: index });
													setModalDelete(true)
												}}>
												<i className="uil-times"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>

		</>
	)
}

export default Index
