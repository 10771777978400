import React from "react"

import Update from "../components/Update"

import Welcome from "pages/dashboard/Welcome"

// Authentication related pages
import Login from "../pages/authentication/Login"
import ForgotPassword from "pages/authentication/ForgotPassword"
import Cheque from "pages/authentication/Cheque"

import ChequesIndex from "../pages/pos/cheques/Index"
import DebitCreditIndex from "../pages/pos/debitCredit/Index"

import DocumentsInIndex from "../pages/pos/documentsIn/Index"
import DocumentsInCreateUpdate from "../pages/pos/documentsIn/CreateUpdate"
import DocumentsInPreview from "../pages/pos/documentsIn/Preview"

import DocumentsOutIndex from "../pages/pos/documentsOut/Index"
import DocumentsOutCreateUpdate from "../pages/pos/documentsOut/CreateUpdate"
import DocumentsPreview from "../pages/pos/documentsOut/Preview"

import WriteOffIndex from "../pages/pos/writeOff/Index"
import WriteOffCreateUpdate from "../pages/pos/writeOff/CreateUpdate"
import WriteOffPreview from "../pages/pos/writeOff/Preview"

import GroupingIndex from "../pages/pos/grouping/Index"
import GroupingCreateUpdate from "../pages/pos/grouping/CreateUpdate"

import HistoryIndex from "../pages/pos/history/Index"

import InventoryIndex from "../pages/pos/inventory/Index"
import InventoryCreateUpdate from "../pages/pos/inventory/CreateUpdate"
import InventorySocketCreateUpdate from "../pages/pos/inventory/SocketCreateUpdate"
import InventoryPreview from "../pages/pos/inventory/Preview"

import NomenclatureIndex from "../pages/pos/nomenclature/Index"

import RevaluationIndex from "../pages/pos/revaluation/Index"

import NotificationsIndex from "../pages/pos/notifications/Index"

import PriceTagsIndex from "../pages/pos/priceTags/Index"
import ReportsIndex from "../pages/pos/reports/Index"

import OrganizationBalance from "../pages/organization/Balance"

import ScaleIndex from "../pages/pos/scale/Index"
import ScaleCreateUpdate from "../pages/pos/scale/CreateUpdate"

import TransferIndex from "../pages/pos/transfer/Index"
import TransferCreateUpdate from "../pages/pos/transfer/CreateUpdate"
import TransferPreview from "../pages/pos/transfer/Preview"

import PackageIndex from '../pages/pos/package/Index'
import PackageCreateUpdate from '../pages/pos/package/CreateUpdate'

import PromotionIndex from '../pages/pos/promotion/Index'

import ReportBalance from '../pages/pos/reports/Balance'
import ReportIncomes from '../pages/pos/reports/Incomes'
import ReportWriteOff from '../pages/pos/reports/WriteOff'
import ReportSales from '../pages/pos/reports/Sales'
import ReportExpenses from '../pages/pos/reports/Expenses'
import ReportExpirationDate from '../pages/pos/reports/ExpirationDate'
import ReportReturnedItems from '../pages/pos/reports/ReturnedItems'
import ReportGrossIncome from '../pages/pos/reports/GrossIncome'
import ReportCategoryManagement from '../pages/pos/reports/CategoryManagement'
import ReportOrganizations from '../pages/pos/reports/Organizations'
import ReportOrganizationsInner from '../pages/pos/reports/OrganizationsInner'
import ReportClients from '../pages/pos/reports/Clients'
import ReportClientsInnerDebts from '../pages/pos/reports/ClientsInnerDebts'
import ReportClientsInnerCheques from '../pages/pos/reports/ClientsInnerCheques'
import ReportSummaryKPI from '../pages/pos/reports/SummaryKPI'
import ReportSummary from '../pages/pos/reports/Summary'
import ReportCashiers from '../pages/pos/reports/Cashiers'

import ReportFrozenFunds from '../pages/pos/analytics/FrozenFunds'
import ReportRecommendedOrders from '../pages/pos/analytics/RecommendedOrders'
import Abc from "pages/pos/analytics/Abc"
import Marginality from "pages/pos/analytics/Marginality"
import Oos from "pages/pos/analytics/Oos"
import ProductAction from "pages/pos/analytics/ProductAction"
import ProductUnsold from "pages/pos/analytics/ProductUnsold"

// GENERAL
import Settings from "../pages/general/settings/Index"

import PosIndex from "../pages/general/poses/Index"
import PosCreateUpdate from "../pages/general/poses/CreateUpdate"

import CashboxIndex from "../pages/general/cashbox/Index"
import ShiftIndex from "../pages/general/cashbox/Shift"

import WorkersIndex from "../pages/general/myWorkers/Index"
import WorkersCreateUpdate from "../pages/general/myWorkers/CreateUpdate"

import SuppliersIndex from "../pages/general/suppliers/Index"

import CurrencyIndex from "../pages/general/currency/Index"

import ClientsIndex from "../pages/general/clients/Index"
import MyOrganizationUpdate from "../pages/general/myOrganization/CreateUpdate"

import PurchaseIndex from "../pages/pos/sdPurchase/Index"
import PurchaseCreateUpdate from "../pages/pos/sdPurchase/CreateUpdate"

import ProductsIndex from "../pages/general/products/Index"
import ProductsCreateUpdate from "../pages/general/products/CreateUpdate"

import ProductCategoriesIndex from "../pages/general/productCategories/Index"
import ProductCategoriesCreateUpdate from "../pages/general/productCategories/CreateUpdate"

import ExpenseIndex from "../pages/general/expense/Index"

import DealerCreateUpdate from "../pages/general/dealer/CreateUpdate"
import DealerIndex from "../pages/general/dealer/Index"

// ADMIN
import Test from "../pages/test"
import BarcodeForm from "pages/pos/Inventarization/BarcodeForm"
import ViewPurchase from "pages/pos/sdPurchase/ViewPurchase"


const userRoutes = [
	{ path: "/welcome", component: <Welcome /> },

	{ path: "/cheques", component: <ChequesIndex /> },

	{ path: "/debit-credit", component: <DebitCreditIndex /> },

	{ path: "/documents-in", component: <DocumentsInIndex /> },
	{ path: "/documents-in/create", component: <DocumentsInCreateUpdate /> },
	{ path: "/documents-in/update/:id", component: <DocumentsInCreateUpdate /> },
	{ path: "/documents-in/preview/:id", component: <DocumentsInPreview /> },

	{ path: "/sd-purchase", component: <PurchaseIndex /> },
	{ path: "/sd-purchase/create", component: <PurchaseCreateUpdate /> },
	{ path: "/sd-purchase/view/:id", component: <ViewPurchase /> },

	{ path: "/documents-out", component: <DocumentsOutIndex /> },
	{ path: "/documents-out/create", component: <DocumentsOutCreateUpdate /> },
	{ path: "/documents-out/preview/:id", component: <DocumentsPreview /> },

	{ path: "/write-off", component: <WriteOffIndex /> },
	{ path: "/write-off/create", component: <WriteOffCreateUpdate /> },
	{ path: "/write-off/preview/:id", component: <WriteOffPreview /> },

	{ path: "/grouping", component: <GroupingIndex /> },
	{ path: "/grouping/create", component: <GroupingCreateUpdate /> },
	{ path: "/grouping/update/:paramsPosId/:paramsProductGroupId", component: <GroupingCreateUpdate /> },

	{ path: "/history", component: <HistoryIndex /> },

	{ path: "/inventory", component: <InventoryIndex /> },
	{ path: "/inventory/create", component: <InventoryCreateUpdate /> },
	{ path: "/inventory/update/:id", component: <InventoryCreateUpdate /> },
	{ path: "/inventory/create-socket", component: <InventorySocketCreateUpdate /> },
	{ path: "/inventory/update-socket/:id", component: <InventorySocketCreateUpdate /> },
	{
		path: "/inventory/preview/:id", component: <InventoryPreview />
	},

	{ path: "/nomenclature", component: <NomenclatureIndex /> },

	{ path: "/revaluation", component: <RevaluationIndex /> },

	{ path: "/cashbox", component: <CashboxIndex /> },
	{ path: "/cashbox/shift/:id?", component: <ShiftIndex /> },

	{ path: "/notifications", component: <NotificationsIndex /> },

	{ path: "/workers", component: <WorkersIndex /> },
	{ path: "/workers/create", component: <WorkersCreateUpdate /> },
	{ path: "/workers/update/:id", component: <WorkersCreateUpdate /> },

	{ path: "/price-tags", component: <PriceTagsIndex /> },

	{ path: "/reports", component: <ReportsIndex /> },
	{ path: "/reports/organizations/:id", component: <ReportsIndex /> },
	{ path: "/reports/clients/:id", component: <ReportsIndex /> },

	{ path: "/organization-balance", component: <OrganizationBalance /> },

	{ path: "/scale", component: <ScaleIndex /> },
	{ path: "/scale/create", component: <ScaleCreateUpdate /> },

	{ path: "/transfer", component: <TransferIndex /> },
	{ path: "/transfer/create", component: <TransferCreateUpdate /> },
	{ path: "/transfer/update/:id", component: <TransferCreateUpdate /> },
	{ path: "/transfer/preview/:id", component: <TransferPreview /> },

	{ path: "/package", component: <PackageIndex /> },
	{ path: "/package/create", component: <PackageCreateUpdate /> },
	{ path: "/package/update/:id", component: <PackageCreateUpdate /> },

	{ path: "/promotion", component: <PromotionIndex /> },

	{ path: "/report/balance", component: <ReportBalance /> },
	{ path: "/report/cashiers", component: <ReportCashiers /> },
	{ path: "/report/category-managment", component: <ReportCategoryManagement /> },
	{ path: "/report/clients", component: <ReportClients /> },
	{ path: "/report/clients-inner-cheques", component: <ReportClientsInnerCheques /> },
	{ path: "/report/clients-inner-debts", component: <ReportClientsInnerDebts /> },
	{ path: "/report/expenses", component: <ReportExpenses /> },
	{ path: "/report/expiration-date", component: <ReportExpirationDate /> },
	{ path: "/report/gross-income", component: <ReportGrossIncome /> },
	{ path: "/report/incomes", component: <ReportIncomes /> },
	{ path: "/report/suppliers", component: <ReportOrganizations /> },
	{ path: "/report/suppliers-inner", component: <ReportOrganizationsInner /> },
	{ path: "/report/return-products", component: <ReportReturnedItems /> },
	{ path: "/report/sales", component: <ReportSales /> },
	{ path: "/report/summary", component: <ReportSummary /> },
	{ path: "/report/summary-kpi", component: <ReportSummaryKPI /> },
	{ path: "/report/write-off", component: <ReportWriteOff /> },

	{ path: "/report/frozen-funds", component: <ReportFrozenFunds /> },
	{ path: "/report/recommended-orders", component: <ReportRecommendedOrders /> },
	{ path: "/report/abc", component: <Abc /> },
	{ path: "/report/marginality", component: <Marginality /> },
	{ path: "/report/oos", component: <Oos /> },
	{ path: "/report/product-action", component: <ProductAction /> },
	{ path: "/report/product-unsold", component: <ProductUnsold /> },

	// GENERAL
	{ path: "/settings", component: <Settings /> },

	{ path: "/pos", component: <PosIndex /> },
	{ path: "/pos-create", component: <PosCreateUpdate /> },
	{ path: "/pos-update/:id", component: <PosCreateUpdate /> },

	{ path: "/clients", component: <ClientsIndex /> },

	{ path: "/my-organization", component: <MyOrganizationUpdate /> },

	{ path: "/suppliers", component: <SuppliersIndex /> },

	{ path: "/currency", component: <CurrencyIndex /> },

	{ path: "/products", component: <ProductsIndex /> },
	{ path: "/products-create", component: <ProductsCreateUpdate /> },
	{ path: "/products-update/:id", component: <ProductsCreateUpdate /> },
	{ path: "/product-categories", component: <ProductCategoriesIndex /> },
	{ path: "/product-categories-create", component: <ProductCategoriesCreateUpdate /> },
	{ path: "/product-categories-update/:id", component: <ProductCategoriesCreateUpdate /> },

	{ path: "/dealer", component: <DealerIndex /> },
	{ path: "/dealer/create", component: <DealerCreateUpdate /> },
	{ path: "/dealer/update/:id", component: <DealerCreateUpdate /> },

	{ path: "/expense", component: <ExpenseIndex /> },

	// ADMIN
	{ path: "/test", component: <Test /> },

	{ path: "/update/:id", component: <Update /> },

	// this route should be at the end of all other routes
	// { path: "/", exact: true, component: () => <Navigate to="/dashboard" /> },
	// 404 route not found
	// { path: "*", exact: true, component: () => <Navigate to="/dashboard" /> },
]

const authRoutes = [
	{ path: "/auth/login", component: <Login /> },
	{ path: "/forgot-password", component: <ForgotPassword /> },
	{ path: "/cheque/:shiftId/:chequeNumber", component: <Cheque /> },
	{ path: "/inventorization", component: <BarcodeForm /> },
]

export { userRoutes, authRoutes }